import { FC } from 'react';

import { useApplication } from '@common/application';
import { PayOff } from '@custom-components/weDoenHetNuCampaign/PayOff';
import {
  CardContainer,
  CardSpeechBubble,
  CardSpeechBubbleHeading,
  CardSpeechBubbleText,
  CardBackground,
  PayOffWrapper,
  StyledText,
} from '@custom-components/weDoenHetNuCampaign/WeDoenHetNuCard';
import { useLinkComponent } from '@link';
import { wrap } from '@sitecore/common';
import { WeDoenHetNuCard as Props } from '@sitecore/types/manual/WeDoenHetNuCard';
import { Bleed, Stack, Heading, ButtonLink, Grid, Stretch, Text, Box } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { HeadingProps } from '@sparky/types';

const WeDoenHetNuCard: FC<Props> = ({ fields, params }) => {
  const Link = useLinkComponent();
  const headingLevel: HeadingProps['as'] = params?.headingLevel ?? 'h1';
  const highlightedHeading = params?.highlightTitleOption;
  const color = params?.color || 'weDoenHetNuVarTwo';
  const isDesktop = useMediaQuery('md');
  const { language } = useApplication();

  const HighlightTitle = ({ title, isHighlighted }: { title: { value: string }; isHighlighted: boolean }) => {
    return isHighlighted ? (
      <Text color="textInverted">{wrap(title)}</Text>
    ) : (
      <StyledText color={color}>{wrap(title)}</StyledText>
    );
  };

  return (
    <CardContainer>
      <Grid columns="1" gridTemplateRows="1fr" alignY="end">
        <Grid.Item gridColumn="1 / -1" gridRow="1 / -1">
          <Box paddingLeft={{ initial: '0', lg: '8' }}>
            <CardSpeechBubble backgroundColor={color} language={language}>
              <Stack direction="column" gap="6">
                <Stack.Item>
                  <CardSpeechBubbleHeading as={headingLevel} size={isDesktop ? 'heading2xl' : 'headingL'}>
                    {fields.firstPartTitle && (
                      <HighlightTitle isHighlighted={highlightedHeading === 'first'} title={fields.firstPartTitle} />
                    )}
                    {fields.secondPartTitle && (
                      <HighlightTitle isHighlighted={highlightedHeading === 'second'} title={fields.secondPartTitle} />
                    )}
                  </CardSpeechBubbleHeading>
                </Stack.Item>
                {fields.text?.value && (
                  <Stack.Item>
                    <CardSpeechBubbleText>
                      <Heading as="h2" size={{ initial: '2XS', md: 'XS' }}>
                        {wrap(fields.text)}
                      </Heading>
                    </CardSpeechBubbleText>
                  </Stack.Item>
                )}
                {fields.link?.value?.href && fields.link?.value?.text && (
                  <Stack.Item>
                    <Link
                      editable={fields.link.editable}
                      linkType={fields.link.value.linktype}
                      linkValue={fields.link.value}>
                      <ButtonLink action="primary" size="regular">
                        {fields.link?.value.text}
                      </ButtonLink>
                    </Link>
                  </Stack.Item>
                )}
              </Stack>
            </CardSpeechBubble>
          </Box>
        </Grid.Item>
        <Stretch height={{ initial: true, lg: false }} width="auto">
          <Grid.Item gridColumn="1 / -1" gridRow="1 / -1">
            <Stretch height={{ initial: true, lg: false }} width="auto">
              <Bleed horizontal={{ initial: 'gridGutter', lg: '0' }}>
                <CardBackground backgroundColor={color} language={language}>
                  <PayOffWrapper language={language}>
                    <PayOff backgroundColor={color} language={language}></PayOff>
                  </PayOffWrapper>
                </CardBackground>
              </Bleed>
            </Stretch>
          </Grid.Item>
        </Stretch>
      </Grid>
    </CardContainer>
  );
};

export default WeDoenHetNuCard;
